import React, { memo } from 'react';
import {
  FaInstagramSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaGithubSquare,
} from 'react-icons/fa';
function SocialMedia(props) {
  const postion = ' absolute left-0 top-full';
  const transition = 'transition duration-300 ease-linear';

  return (
    <>
      <ul
        className={`text-neutral-50 z-50 p-2 text-center ${transition}  rounded-xl  w-full flex flex-row ${
          props.ishovered ? 'flex md:flex-col opacity-100' : props.hidesocial
        } ${props.customClasses}`}
      >
        <li className='px-2 mb-2'>
          <a href='https://www.instagram.com/amjarmed_1/' target='_blank'>
            <FaInstagramSquare className='hover:instagram-c inline' />
          </a>
        </li>
        <li className='px-2 mb-2 '>
          <a href='https://www.linkedin.com/in/amjarmed/' target='_blank'>
            <FaLinkedin className='hover:linkedin-c inline' />
          </a>
        </li>
        <li className='px-2 mb-2'>
          <a href='https://twitter.com/amjarmed_1' target='_blank'>
            <FaTwitterSquare className='hover:twitter-c inline' />
          </a>
        </li>
        <li className='px-2 mb-2'>
          <a href='https://github.com/amjarmed' target='_blank'>
            <FaGithubSquare className='hover:github-cz inline' />
          </a>
        </li>
      </ul>
    </>
  );
}
// setting default props
// SocialMedia.defaultProps = {
//   ishovered: false,
// };

export default memo(SocialMedia);
