import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaRegWindowClose } from 'react-icons/fa';
import SocialMedia from './SocialMedia';
import { memo } from 'react';
import { FaChevronDown } from 'react-icons/fa';
//  list icons
import { FaUser, FaLaptopCode, FaEnvelope } from 'react-icons/fa';
import { IoIosCodeWorking } from 'react-icons/io';
import { PiStudent } from 'react-icons/pi';

// motion'
import { motion } from 'framer-motion';
// windows size
import WindowSize from '../utils/WindowSize';

/////
const NavBar = () => {
  // navbar height to keep content away
  const navbarRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(0);
  useEffect(() => {
    if (navbarRef.current) {
      const { clientHeight } = navbarRef.current;
      setNavbarHeight(clientHeight);
    }
  });

  //
  const [navopen, setNavopen] = useState(true);
  const [ishover, setHover] = useState(false);

  // handle navbar on mobile devices
  const handleNavbarOpen = () => {
    setNavopen((prevstate) => !prevstate);
  };
  //handle social media open and close list
  const handleSocialHover = () => {
    setHover((prevstate) => !prevstate);
  };

  useEffect(() => {
    const handleOnMouseEnter = () => {
      setHover(true);
    };
    const handleOnMouseLeave = () => {
      setHover(false);
    };
    const listSocial = document.getElementById('listSocial');

    listSocial.addEventListener('mouseenter', handleOnMouseEnter);
    listSocial.addEventListener('mouseleave', handleOnMouseLeave);
    return () => {
      listSocial.removeEventListener('mouseenter', handleOnMouseEnter);
      listSocial.removeEventListener('mouseleave', handleOnMouseLeave);
    };
  }, []);

  // li style
  const listStyle =
    'mb-5 md:mb-0 border p-2 md:border-none md:p-0 hover:scale-105 md:scale-100 transition';
  return (
    <div
      className='portfolio-navbar'
      id='portfolio-navbar'
      ref={navbarRef}
      style={{ paddingBottom: { navbarHeight } }}
    >
      <div className=' fixed top-1 left-1 md:hidden   px-5 mt-5 cursor-pointer z-50  '>
        <FaBars
          className={`text-neutral-50  ${navopen ? 'block' : 'hidden'}`}
          size={40}
          onClick={handleNavbarOpen}
        />
        <FaRegWindowClose
          className={`text-neutral-50  ${navopen ? 'hidden' : 'block'}`}
          size={40}
          onClick={handleNavbarOpen}
        />
      </div>
      <header
        // transform -translate-x-full
        className={`fixed md:absolute top-0 left-0 w-max  min-h-screen  md:min-h-max md:w-full  z-30 bg-neutral-950 md:bg-transparent transition duration-300  ease-linear transform -translate-x-full  md:transform-none md:scale-100 pt-8 md:pt-0 ${
          !navopen ? 'transform-none   translate-x-full' : ''
        } `}
        onBlur={handleNavbarOpen}
        // onMouseOutCapture={handleNavbarOpen}
      >
        <div className='container mx-auto'>
          <nav
            className={`flex  md:flex flex-wrap md:text-start  justify-between items-start  text-neutral-300 py-3 px-2 font-semibold `}
          >
            <p className='w-full md:w-1/2 p-4 text-green-800 text-center md:text-start'>
              <Link to='/'>
                Amjarmed <span class=''>.</span>
              </Link>
            </p>
            <ul className='w-full md:w-1/2 p-4 flex flex-col md:flex-row  justify-between content-start md:items-start uppercase'>
              <motion.li
                className={listStyle}
                animate={{
                  rotate: WindowSize() ? 0 : -45,
                }}
              >
                <Link
                  to='../pages/about'
                  className='hover:text-green-800 transition flex justify-around md:block items-center'
                >
                  <FaUser className='md:hidden w-3/12 md:w-auto' />
                  <span className='w-3/4 md:w-auto'>about</span>
                </Link>
              </motion.li>
              <li className={listStyle}>
                <Link
                  to='../pages/skills'
                  className='hover:text-green-800 transition flex justify-around md:block items-center'
                >
                  <FaLaptopCode className='md:hidden w-3/12 md:w-auto' />
                  <span className='w-3/4 md:w-auto'>skills</span>
                </Link>
              </li>
              <li className={listStyle}>
                <Link
                  to='../pages/work'
                  className='hover:text-green-800 transition flex justify-around md:block items-center'
                >
                  <IoIosCodeWorking className='md:hidden w-3/12 md:w-auto' />
                  <span className='w-3/4 md:w-auto'>Work</span>
                </Link>
              </li>
              <li className={listStyle}>
                <Link
                  to='../pages/resume'
                  className='hover:text-green-800 transition flex justify-around md:block items-center'
                >
                  <PiStudent className='md:hidden w-3/12 md:w-auto' />
                  <span className='w-3/4 md:w-auto'>resume</span>
                </Link>
              </li>

              <li className={listStyle}>
                <Link
                  to='../pages/contact'
                  className='hover:text-green-800 transition flex justify-around md:block items-center'
                >
                  <FaEnvelope className='md:hidden w-3/12 md:w-auto' />
                  <span className=' w-3/4 md:w-auto'>Contact</span>
                </Link>
              </li>
              <li className={listStyle} id='listSocial'>
                <div className='hover:text-green-800 transition cursor-pointer flex justify-around md:block items-center'>
                  <FaChevronDown className='md:inline-block w-3/12 md:w-auto' />

                  <span className='md:inline-block w-3/4 md:w-auto'>
                    Social
                  </span>
                </div>
                <SocialMedia
                  className='hidden'
                  ishovered={ishover}
                  customClasses={
                    'absolute left-0 top-full mt-4 md:mt-1 border bg-neutral-900 bg-opacity-35'
                  }
                  hidesocial={'hidden opacity-0'}
                />
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default memo(NavBar);
