import './App.css';
import React, { Suspense } from 'react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import { useLocation, Routes, Route } from 'react-router-dom';
import Loading from '../Loader/Loading';
import NavBar from '../NavBar';

const Hero = React.lazy(() => import('../../pages/Hero'));
const About = React.lazy(() => import('../../pages/About'));
const Work = React.lazy(() => import('../../pages/Work'));
const Resume = React.lazy(() => import('../../pages/Resume'));
const Contact = React.lazy(() => import('../../pages/Contact'));
const Skills = React.lazy(() => import('../../pages/Skills'));

function App() {
  const location = useLocation();
  const navheight = 300;
  const transition = 'transition duration-1000 ease-in-out';
  return (
    <>
      <NavBar />

      <main
        className={`main-content  min-h-screen flex flex-col flax-wrap mx-auto items-center  content-center  transition duration-300 ease-in-out pt-11 relative text-neutral-100  ${
          location.pathname === '/home' ? 'justify-end' : 'justify-center'
        }`}
      >
        {/* replace hero with content in pages */}
        <Routes>
          <Route
            index
            path='/'
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Hero className={transition} />
              </Suspense>
            }
          />
          <Route
            path='/pages/about'
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <About className={transition} />
              </Suspense>
            }
          />
          <Route
            path='/pages/skills'
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Skills className={transition} />
              </Suspense>
            }
          />
          <Route
            path='/pages/work'
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Work className={transition} />
              </Suspense>
            }
          />
          <Route
            path='/pages/resume'
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Resume className={transition} />
              </Suspense>
            }
          />
          <Route
            path='/pages/contact'
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Contact className={transition} />
              </Suspense>
            }
          />
        </Routes>

        <footer className='p-2 md:p-4'></footer>
      </main>

      <SpeedInsights />
      <Analytics />
      {/* <Loading /> */}
    </>
  );
}

export default App;
